:root[data-theme="light"] {
  --colors-gradientVioletAlt: #fff;
  /* --colors-gradientVioletAlt: #000; */
  --colors-cardBorder: #6f6565;
  --colors-textSubtle: #fff;
  --colors-secondary: ;
  --colors-primaryBright: #ffb800;
  --colors-success: #1aae69;
  --colors-primary: #4B84F3;
  --colors-textDisabled: #000;
  --colors-textSubtle: #000;
  --colors-white: #000;
  --colors-gradientGold: #4B84F3;
  --colors-failure: red;

  .matchbox {
    background-color: rgb(216 212 212 / 23%);
    padding-inline: 15px;
    border-radius: 6px;
    padding-block: 15px;
    border: 1px solid #4B84F3;
  }
  .drawticket{
    color: #000;
  }
  .drawcounttext{
    color: #000;
  }
  .iowsGo {
    color: #000;
  }

  .steplist {
    display: inline-block;
    padding: 10px;
    background: #4B84F3;

    /* background:  linear-gradient(to top, #1e8fe6, #80A1FF);   */
    border-radius: 0 10px;
    color: #fff !important;
  }

  .toggled {
    background-color: #4B84F3 !important;
  }

  .my-custom-ribbon {
    background: #4B84F3;
  }

  .my-custom-ribbon::before {
    background: #4B84F3;

  }

  .my-custom-ribbon::after {
    background: #4B84F3;

  }

  .change {
    background-color: red;
  }

  .anotherClass {
    background-color: #2d67d8;
  }

  .backChange {
    background: #ed4b9e57;
  }

  .ribbontittle {
    background: #4B84F3;
  }

  .numberticket {
    color: #000;
  }

  .balls {
    color: #000 !important;
  }

  .tickettext {

    color: #fff;
  }
}

:root[data-theme="dark"] {
  --colors-gradientVioletAlt: #fff;
  --colors-gradientVioletAlt: #000;
  --colors-cardBorder: #6f6565;
  --colors-textSubtle: #fff;
  --colors-secondary: ;
  --colors-primaryBright: #ffb800;
  --colors-success: #1aae69;
  --colors-primary: #498fec;
  --colors-textDisabled: #fff;
  --colors-primary: #eb7dff;
  --colors-failure: rgb(158, 14, 14);
  --colors-gradientGold: linear-gradient(to top, #eb7dff, #498fec);
  --colors-backgroundAlt: #000;

  .matchbox {
    background-color: rgba(22, 27, 22, 0.8509803922);
    padding-inline: 15px;
    border-radius: 6px;
    padding-block: 15px;
    border: 1px solid #eb7dff;
  }
}


.CardHeader__StyledCardHeader-sc-b1def301-0 {
  border-radius: 22px 22px 0 0;

}

.ticketimage {
  width: 80%;
  margin: 0 auto;
}

.ribbontittle {
  background: #eb7dff;
}

.imgticket {
  position: relative;
}

.tickettext {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tickettext p {
  font-size: 37px;
  text-align: center;
}

.tickettext h2 {
  font-size: 37px;
  text-align: center;
}

.hfgtext {
  font-size: 46px;
  font-weight: bolder;
  padding: 10px;
  text-align: center;
  color: #8800b5;
}

.ticketbtn {
  margin-top: 35px;

}

.numberticket {
  color: #fff;
}

.imgticket img {
  display: block;
}

.imgticket2 img {
  display: none;
}

@media screen and (max-width:992px) {


  .tickettext p {
    font-size: 25px;

  }

  .tickettext h2 {
    font-size: 25px;

  }

  .hfgtext {
    font-size: 35px;
    padding: 5px;

  }

  .ticketimage {
    width: 100%;
  }
}

@media screen and (max-width:768px) {
  .imgticket img {
    display: none;
    max-width: 300px;
  }

  .imgticket2 img {
    display: block;
  }

  .tickettext {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  

}

